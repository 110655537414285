<template>
  <div
    class="personalized-table-body"
    data-cy="cmp-table-body"
  >
    <component
      :is="rowComponent"
      v-for="(singleRow, index) in filledRowList"
      :key="singleRow.id || singleRow"
      :item="singleRow"
      :show-skeleton="showSkeleton"
      :column-widths="columnWidths"
      :options="rowOptions"
      :index="index"
      class="personalized-table-row"
      @colevent="$emit('colevent', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'PersonalizedTableBody',
  props: {
    rowList: {
      type: Array,
      default: () => [],
    },
    rowOptions: {
      type: Object,
      default: () => ({}),
    },
    rowComponent: {
      validator(val) {
        return typeof val === 'object' && typeof val.render === 'function';
      },
      required: true,
    },
    columnWidths: {
      type: Array,
      default: () => [],
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['colevent'],
  computed: {
    filledRowList() {
      if (!this.showSkeleton) return this.rowList;

      // As the provided rowComponent knows best how to render the skeleton,
      // we need to render a defined amount of rows without having a real
      // rowList. We therefore just create an array with numbers for the case
      // where we want to display the skeleton.
      // @TODO: Amount (10) needs to be configurable at some point
      return new Array(10).fill(null).map((_, i) => i + 1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

.personalized-table-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: visible;
}

.personalized-table-row {
  border-bottom: 1px solid $color-grey-200;
}
</style>
